import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { eventContactImg } from "../assets/data";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";

const EventContacts = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const e2 = Boolean(searchParams.get("e2"));

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: e2 ? "sama23" : "wire23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: e2 ? "sama23/agenda" : "agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: e2 ? "sama23/documents" : "documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: e2 ? "event-contacts?e2=true" : "event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: e2 ? "photos?e2=true" : "photos",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%] px-8 ">
      <Header title={t("main.header_02")} />
      <Navbar navLinks={navLinks} />
      <div className="overflow-y-auto">
        <div className="flex flex-col gap-5 lg:flex-row justify-around h-full md:mt-7 lg:mt-14">
          <div className="flex flex-col">
            <h1 className="text-[25px] text-[#01410b] md:text-[30px] lg:text-[35px] font-[600] text-left">
              {t("main.header_10")}
            </h1>
            <div className="flex flex-col gap-2">
              <p className="text-[16px] text-justify  leading-8">
                {t("contact.line_001")}
              </p>
              <div className="flex md:flex-row flex-col w-[100%] md:gap-24 gap-6 my-3 lg:my-5">
                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#8ec375",
                  }}
                >
                  <p className="text-[18px] text-justify  leading-8 font-[600] lg:mb-1">
                    {t("contact.address_01.line_01")}
                  </p>
                  <a
                    href="mailto:giovanni.broussard@un.org"
                    className="text-[16px] text-justify text-blue-600"
                  >
                    {t("contact.address_01.line_02")}
                  </a>
                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_01.line_03")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_01.line_04")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_01.line_05")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_01.line_06")}
                    </p>
                  </div>
                </div>
                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#ff627f",
                  }}
                >
                  <p className="text-[18px] text-justify  leading-8 font-[600] lg:mb-1">
                    {t("contact.address_02.line_01")}
                  </p>
                  <a
                    href="mailto:marion.crepet@un.org"
                    className="text-[16px] text-justify text-blue-600"
                  >
                    {t("contact.address_02.line_02")}
                  </a>
                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_02.line_03")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("contact.address_02.line_04")}
                    </p>
                    <p className="text-[14px]   leading-8">
                      {t("contact.address_02.line_06")}
                    </p>
                    <p className="text-[14px]   leading-8">
                      {t("contact.address_02.line_07")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.8)" }}
            className="h-fit md:h-[250px] lg:h-[400px] md:w-fit lg:w-[400px] rounded-md overflow-hidden flex items-center justify-center"
          >
            <img
              src={eventContactImg}
              alt="about Image"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventContacts;
