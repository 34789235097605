import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Photos from "./pages/Photos";
import { Suspense } from "react";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import AdminPortal from "./pages/AdminPortal";
import LanguageDropdown from "./components/LanguageDropdown";
import { AiOutlineMenu } from "react-icons/ai";
import { useContext } from "react";
import { AppContext } from "./contexts/appContext";
import EventContacts from "./pages/EventContacts";
import Overview from "./pages/EventOne/Overview";
import Agenda from "./pages/EventOne/Agenda";
import Documents from "./pages/EventOne/Documents";
import E2Overview from "./pages/EventTwo/E2Overview";
import E2Agenda from "./pages/EventTwo/E2Agenda";
import E2Documents from "./pages/EventTwo/E2Documents";
import E2Photos from "./pages/EventTwo/E2Photos";
import E2Contacts from "./pages/EventTwo/E2Contacts";
import E3Overview from "./pages/EventThree/E3Overview";
import E3Agenda from "./pages/EventThree/E3Agenda";
import E3Documents from "./pages/EventThree/E3Documents";
import E3Photos from "./pages/EventThree/E3Photos";
import E3Contacts from "./pages/EventThree/E3Contacts";
import AllEvent from "./pages/AllEvent";
import WireEvent from "./pages/WireEvent";
import SamaEvent from "./pages/SamaEvent";
import E3Presentation from "./pages/EventThree/E3Presentation";

function App() {
  const { setSideMenuOpen } = useContext(AppContext);

  return (
    <div
      className="relative w-full h-full"
      style={{
        backgroundImage: `url(${require(`./assets/img/BG/bg0${5}.jpg`)})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="hidden md:block absolute top-3 right-[10px]">
        <LanguageDropdown />
      </div>
      <div
        onClick={() => setSideMenuOpen(true)}
        className="fixed top-3 right-5 md:hidden w-[40px] h-[40px] rounded-full bg-gray-700  flex items-center justify-center  cursor-pointer  hover:opacity-[.8] hover:text-white z-[2]"
      >
        <AiOutlineMenu size={20} className="text-white" />
      </div>

      <Routes>
        {/* common route */}
        <Route path={"/"} element={<LandingPage />}>
          <Route path="allEvent" element={<AllEvent />} />
          <Route index={true} element={<WireEvent />} />
          <Route path="samaEvent" element={<SamaEvent />} />
        </Route>
        <Route path={"/about"} element={<About />} />
        <Route path={"/contacts"} element={<Contacts />} />
        <Route path={"/admin_portal"} element={<AdminPortal />} />

        {/*wire23 event first */}
        <Route path={"/wire23"} element={<Overview />} />
        <Route path={"/agenda"} element={<Agenda />} />
        <Route path={"/documents"} element={<Documents />} />
        <Route path={"/event-contacts"} element={<EventContacts />} />
        <Route path={"/photos"} element={<Photos />} />

        {/*sama23 event two */}
        <Route path={"/sama23"} element={<E2Overview />} />
        <Route path={"/sama23/agenda"} element={<E2Agenda />} />
        <Route path={"/sama23/documents"} element={<E2Documents />} />
        <Route path={"/sama23/photos"} element={<E2Photos />} />
        <Route path={"/sama23/event-contacts"} element={<E2Contacts />} />

        {/*wire24 event three */}
        <Route path={"/wire24"} element={<E3Overview />} />
        <Route path={"/wire24/agenda"} element={<E3Agenda />} />
        <Route path={"/wire24/documents"} element={<E3Documents />} />
        <Route path={"/wire24/photos"} element={<E3Photos />} />
        <Route path={"/wire24/event-contacts"} element={<E3Contacts />} />
        <Route path={"/wire24/presentation"} element={<E3Presentation />} />
      </Routes>
    </div>
  );
}

export default function wrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
