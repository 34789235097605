import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

import { useTranslation } from "react-i18next";

const AdminPortal = () => {
  const { t } = useTranslation();

  const eventLinkstyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    border: "0.3px solid #a0a0a0",
  };

  const navLinks = [
    {
      id: 1,
      name: t("navlink.events"),
      route: "allEvent",
      className: "",
      style: eventLinkstyle,
      parentClassName: "flex-1",
      children: [
        {
          id: "wire",
          name: "WIRE",
          route: "",
          style: eventLinkstyle,
        },
        {
          id: "sama",
          name: "SAMA",
          route: "samaEvent",
          style: eventLinkstyle,
        },
      ],
    },
    {
      id: 2,
      name: t("navlink.about"),
      route: "about",
    },
    {
      id: 3,
      name: t("navlink.contacts"),
      route: "contacts",
    },
    {
      id: 4,
      name: t("navlink.admin_portal"),
      route: "admin_portal",
    },
  ];
  return (
    <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%]  px-8 ">
      <Header title={t("main.header_01")} />
      <Navbar navLinks={navLinks} />
      <h1 className="text-[35px] font-[600] text-left">
        {t("main.header_11")}
      </h1>
    </div>
  );
};

export default AdminPortal;
