import React, { useEffect, useRef, useState } from "react";
import { infoHubIcon } from "../assets/data";

const Footer = () => {
  const [openLink, setOpenLink] = useState(false);
  const linkRef = useRef();

  // const handleInfoHubLink = () => {
  //   setOpenLink(true);
  // };

  useEffect(() => {
    setTimeout(() => {
      setOpenLink(false);
    }, 5000);
  }, [openLink]);

  return (
    <div
      className="fixed bottom-2 left-2"
      style={{
        zIndex: 1000,
      }}
    >
      <img
        src={infoHubIcon}
        alt="icon"
        className="w-[4%] cursor-pointer"
        onClick={() => setOpenLink(true)}
      />
      {openLink && (
        <div className="absolute bottom-0 left-8">
          <a
            href="https://Infohubinnovations.com"
            target="_blank"
            className="t text-blue-900 hover:underline text-center info_hub_link text-[11px] py-[.5px] px-[3px] shadow-md bg-white rounded-md transition delay-500"
            onClick={() => setOpenLink(false)}
          >
            Infohub Innovations Studios
          </a>
        </div>
      )}
    </div>
  );
};

export default Footer;
