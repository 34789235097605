import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { google02, ios01, mobilePic } from "../assets/data";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import "react-datepicker/dist/react-datepicker.css";

const LandingPage = () => {
  const { t } = useTranslation();

  const eventLinkstyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    border: "0.3px solid #a0a0a0",
  };

  const navLinks = [
    {
      id: 1,
      name: t("navlink.events"),
      route: "allEvent",
      className: "",
      style: eventLinkstyle,
      parentClassName: "flex-1",
      children: [
        {
          id: "wire",
          name: "WIRE",
          route: "",
          style: eventLinkstyle,
        },
        {
          id: "sama",
          name: "SAMA",
          route: "samaEvent",
          style: eventLinkstyle,
        },
      ],
    },

    {
      id: 2,
      name: t("navlink.about"),
      route: "about",
    },
    {
      id: 3,
      name: t("navlink.contacts"),
      route: "contacts",
    },
    {
      id: 4,
      name: t("navlink.admin_portal"),
      route: "admin_portal",
    },
  ];

  return (
    <div className="flex flex-col gap-2 w-[100%] h-screen lg:h-screen px-8 overflow-y-auto">
      <Header title={t("main.header_01")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col lg:flex-row items-start gap-10 md:gap-[20px] lg:gap-[100px] max-w-[100%] mx-auto py-4 h-[100%]">
        <div className="flex-1" style={{ paddingBottom: "30px" }}>
          <Outlet />
        </div>
        <div className="flex-1 flex flex-col sm:flex-row gap-5 justify-center h-[100%] items-center md:items-end p-5 md:w-full lg:w-auto">
          <div className="respoMOB mt-[-16px]  w-[180px] sm:w-[140px] md:w-[160px] rounded-md overflow-hidden">
            <img
              src={mobilePic}
              alt=""
              className="w-[100%] h-[100%]  rounded-md "
            />
          </div>
          <div className="flex-1 flex flex-col gap-5 sm:gap-3">
            <p className="text-[14px] text-emerald-950 text-justify  leading-7 sm:leading-6 lg:leading-6">
              {t("para.para_01")}{" "}
            </p>
            <p className="text-[14px] text-emerald-950 text-justify  leading-7 sm:leading-6 lg:leading-6">
              {/* <span>{t("span.span_01")}</span>{" "}
              <span className="font-bold">{t("span.span_02")}</span>{" "} */}
              {t("para.para_02")}
            </p>
            <div className="flex items-center gap-4 w-full justify-center">
              <a
                href="https://apps.apple.com/in/app/wildcrime-compass/id6472936275"
                target="_blank"
                title="Apple Store"
              >
                <img
                  src={ios01}
                  className="w-[150px] sm:w-[130px] md:w-[150px] lg:w-[120px] hover:scale-105 transition ease-in-out delay-50"
                  alt="..."
                />
              </a>
              <a
                href="https://play.google.com/store/search?q=wildcrime%20compass&c=apps&hl=en-IN"
                target="_blank"
                title="Google Play Store"
              >
                <img
                  src={google02}
                  className="w-[150px] sm:w-[130px] md:w-[150px]  lg:w-[120px] hover:scale-105 transition ease-in-out delay-50"
                  alt="..."
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
