import { createContext, useEffect, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
 


  return (
    <AppContext.Provider
      value={{

        sideMenuOpen, setSideMenuOpen,
        selectedDate, setSelectedDate
  
    
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
