import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { pdfImg, card02 } from "../../assets/data";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CardOne from "../../components/Card/CardOne";
import CommonHeading from "../../components/CommonHeading";

// qr
import conceptNoteEnQr from "../../assets/W24qr/WIRE24Concept_NoteEN.png";
import conceptNoteFrQr from "../../assets/W24qr/WIRE24NoteConceptuelleFR.png";
import AdminsNoteEnFrQr from "../../assets/W24qr/AdministrativeNote2024WIRE.png";
import visaYellowEnFrQr from "../../assets/W24qr/AdminNoteVisaYellowFeverVaccine.png";
import participientListQr from "../../assets/W24qr/listOfPracticipent.png";
import cityTourEnQr from "../../assets/W24qr/cityTourEnQr.png";
import cityTourFrQr from "../../assets/W24qr/cityTourFrQr.png";
import cityTourEsQr from "../../assets/W24qr/cityTourESQr.png";
import biletralQr from "../../assets/W24qr/biletralMeeting.png";

import newGif from "../../assets/img/newGif.gif";

const E3Documents = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire24",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "wire24/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "wire24/documents",
    },
    {
      id: "presentatation",
      name: t("navlink.presentation"),
      route: "wire24/presentation",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "wire24/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "wire24/photos",
      badge: () => (
        <div style={{ width: "30px", height: "30px" }}>
          <img src={newGif} style={{ objectFit: "contain", width: "100px" }} />
        </div>
      ),
    },
  ];

  const documentArr = [
    {
      id: 11,
      img: card02,
      title: t("card.header_02_w24"),
      subtitle: t("card.sub_header_02_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/List_Of_Participants.pdf",
      downloadImg: pdfImg,
      qr: participientListQr,
      cardtext: t("card.header_02_w24"),
      downloadFileName: "List_Of_Participants.pdf",
    },
    {
      id: "biletral",
      img: card02,
      title: t("card.doc_header_04_w24"),
      subtitle: t("card.sub_header_01_w24"),
      uploaded: t("card.uploaded_07_w24"),
      href: "https://wildex.ihiapps.com/wire_24/Bilateral_Meetings_2024.pdf",
      downloadImg: pdfImg,
      qr: biletralQr,
      cardtext: t("card.doc_header_04_w24"),
      downloadFileName: "Bilateral_Meetings_2024.pdf",
    },
    {
      id: 4,
      img: card02,
      title: t("card.header_04_e3"),
      subtitle: t("card.sub_header_04_e3"),
      uploaded: t("card.uploaded_01_e3"),
      href: "https://wildex.ihiapps.com/wire_24/WIRE24Concept_NoteEN.pdf",
      downloadImg: pdfImg,
      qr: conceptNoteEnQr,
      cardtext: t("card.header_04_e3"),
      downloadFileName: "WIRE24_ConceptNote_En.pdf",
    },
    {
      id: 5,
      img: card02,
      title: t("card.header_05_e3"),
      subtitle: t("card.sub_header_05_e3"),
      uploaded: t("card.uploaded_01_e3"),
      href: "https://wildex.ihiapps.com/wire_24/WIRE24NoteConceptuelleFR.pdf",
      downloadImg: pdfImg,
      qr: conceptNoteFrQr,
      cardtext: t("card.header_05_e3"),
      downloadFileName: "WIRE24_ConceptNote_Fra.pdf",
    },
    {
      id: 6,
      img: card02,
      title: t("card.header_06_e3"),
      subtitle: t("card.sub_header_06_e3"),
      uploaded: t("card.uploaded_01_e3"),
      href: "https://wildex.ihiapps.com/wire_24/AdministrativeNote2024WIRE.pdf",
      downloadImg: pdfImg,
      qr: AdminsNoteEnFrQr,
      cardtext: t("card.header_06_e3"),
      downloadFileName: "AdministrativeNote2024WIRE.pdf",
    },
    {
      id: 7,
      img: card02,
      title: t("card.header_07_e3"),
      subtitle: t("card.sub_header_07_e3"),
      uploaded: t("card.uploaded_01_e3"),
      href: "https://wildex.ihiapps.com/wire_24/AdminNoteVisaYellowFeverVaccine.pdf",
      downloadImg: pdfImg,
      qr: visaYellowEnFrQr,
      cardtext: t("card.header_07_e3"),
      downloadFileName: "AdminNoteVisaYellowFeverVaccine.pdf",
    },
    {
      id: 8,
      img: card02,
      title: t("card.doc_header_01_w24"),
      subtitle: t("card.sub_header_01_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/DAY_TOUR_7_NOVEMBER_2024_EN.pdf",
      downloadImg: pdfImg,
      qr: cityTourEnQr,
      cardtext: t("card.doc_header_01_w24"),
      downloadFileName: "DAY_TOUR_7_NOVEMBER_2024_EN.pdf",
    },
    {
      id: 9,
      img: card02,
      title: t("card.doc_header_02_w24"),
      subtitle: t("card.sub_header_03_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/DAY_TOUR_7_NOVEMBER_2024_FR.pdf",
      downloadImg: pdfImg,
      qr: cityTourFrQr,
      cardtext: t("card.doc_header_02_w24"),
      downloadFileName: "DAY_TOUR_7_NOVEMBER_2024_FR.pdf",
    },
    {
      id: 10,
      img: card02,
      title: t("card.doc_header_03_w24"),
      subtitle: t("card.sub_header_04_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/DAY_TOUR_7_NOVEMBER_2024_ES.pdf",
      downloadImg: pdfImg,
      qr: cityTourEsQr,
      cardtext: t("card.doc_header_03_w24"),
      downloadFileName: "DAY_TOUR_7_NOVEMBER_2024_ES.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02_03")} />

      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_04")}</CommonHeading>
        <div
          style={{ paddingBottom: 50 }}
          className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]"
        >
          {documentArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default E3Documents;
