import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";

import { card01, pdfImg } from "../../assets/data";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CommonHeading from "../../components/CommonHeading";
import CardOne from "../../components/Card/CardOne";

// qr
import programAgengaEnQr from "../../assets/W23qr/WIRE23_EngAgenda.png";
import programAgengaEspQr from "../../assets/W23qr/WIRE23_EspAgenda.png";
import programAgengaFrQr from "../../assets/W23qr/WIRE23_FraAgenda.png";
import admininsNotesEnQr from "../../assets/W23qr/WIRE23_AdminNotes.png";
import partticipentListEnQr from "../../assets/W23qr/WIRE23_Participants.png";

const Agenda = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "photos",
    },
  ];

  const agendaArr = [
    {
      id: 1,
      img: card01,
      title: t("card.header_01"),
      subtitle: t("card.sub_header_01"),
      uploaded: t("card.uploaded_01"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE23_EngAgenda.pdf",
      downloadImg: pdfImg,
      qr: programAgengaEnQr,
      cardtext: t("card.header_01"),
      downloadFileName: "WIRE23_EngAgenda.pdf",
    },
    {
      id: 2,
      img: card01,
      title: t("card.header_02"),
      subtitle: t("card.sub_header_02"),
      uploaded: t("card.uploaded_02"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE23_EspAgenda.pdf",
      downloadImg: pdfImg,
      qr: programAgengaEspQr,
      cardtext: t("card.header_02"),
      downloadFileName: "WIRE23_EspAgenda.pdf",
    },
    {
      id: 3,
      img: card01,
      title: t("card.header_03"),
      subtitle: t("card.sub_header_03"),
      uploaded: t("card.uploaded_03"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE23_FraAgenda.pdf",
      downloadImg: pdfImg,
      qr: programAgengaFrQr,
      cardtext: t("card.header_03"),
      downloadFileName: "WIRE23_FraAgenda.pdf",
    },
    {
      id: 4,
      img: card01,
      title: t("card.header_04"),
      subtitle: t("card.sub_header_04"),
      uploaded: t("card.uploaded_04"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE23_AdminNotes.pdf",
      downloadImg: pdfImg,
      qr: admininsNotesEnQr,
      cardtext: t("card.header_04"),
      downloadFileName: "WIRE23_AdminNotes.pdf",
    },
    {
      id: 5,
      img: card01,
      title: t("card.header_041"),
      subtitle: t("card.sub_header_041"),
      uploaded: t("card.uploaded_041"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE23_Participants.pdf",
      downloadImg: pdfImg,
      qr: partticipentListEnQr,
      cardtext: t("card.header_041"),
      downloadFileName: "WIRE23_Participants.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_03")}</CommonHeading>
        <div className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]">
          {agendaArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Agenda;
