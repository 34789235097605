import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { BsFillCalendarPlusFill } from "react-icons/bs";
import {
  closeIcon,
  evaluation,
  imgA,
  imgB,
  imgC,
  imgF,
  imgG,
  imgGpng,
  imgH,
  imgI,
  imgJ,
  qrwhatsapp,
  rating,
  tick,
  whatsappIcon,
} from "../../assets/data";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import { AiOutlineHome } from "react-icons/ai";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import Modal from "../../components/Modal";
import CommonHeading from "../../components/CommonHeading";

const Overview = () => {
  const { t } = useTranslation();
  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "photos",
    },
  ];

  const [showInput, setShowInput] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOpen1 = () => {
    setOpen1(!open1);
  };

  const toggleShowInput = () => {
    setShowInput(!showInput);
    setCode("");
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setCode(val);
    setError("");
  };

  const handleSubmit = () => {
    if (!code) {
      return setError("Please enter code");
    } else if (code === "WIRE2023") {
      handleOpen();
    } else {
      return setError("Incorrect code");
    }
  };

  return (
    <>
      <Modal open={open}>
        <div className="relative">
          <div
            className="absolute top-1.5 right-1.5"
            onClick={() => {
              handleOpen();
              toggleShowInput();
            }}
          >
            <img
              src={closeIcon}
              className="w-[40px]"
              style={{ filter: "invert(100%)" }}
            />
          </div>
          <img src={qrwhatsapp} />
          <div className="absolute bottom-2 w-[100%] flex items-center justify-center">
            <a
              href="https://chat.whatsapp.com/CWKvhb6u6Vd7EU0pUcNWGs"
              target="_blank"
              className="text-[14px] mt-[4px] text-blue-700 hover:underline font-[700]"
            >
              {t("click_here")}
            </a>
          </div>
        </div>
      </Modal>

      <Modal open={open1}>
        <div className="relative">
          <div
            className="absolute top-1.5 right-1.5"
            onClick={() => {
              handleOpen1();
            }}
          >
            <img
              src={closeIcon}
              className="w-[40px]"
              style={{ filter: "invert(100%)" }}
            />
          </div>
          <img src={evaluation} className="w-[450px]" />
          <div className="absolute bottom-5 w-[100%] flex items-center justify-center">
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=2zWeD09UYE-9zF6kFubccHDbHGw-g1JBoqrQ_2sTJ6FURjBWOVNXRkI5MzVaVUpKMkswWFpNVTExSy4u"
              target="_blank"
              className="text-[14px]  hover:underline center text-[#FFFFFF] "
            >
              {t("click_here")}
            </a>
          </div>
        </div>
      </Modal>

      <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%]  px-8">
        <Header title={t("main.header_02")} />
        <Navbar navLinks={navLinks} />
        <div className="grid md:grid-cols-1 lg:grid-cols-2  lg:gap-[50px]  items-start mx-auto overflow-y-auto">
          {/* Overview */}

          <div className="flex flex-col gap-2">
            <CommonHeading>{t("main.header_05")}</CommonHeading>

            <p className="mt-8 text-[16px] text-emerald-950 text-justify  leading-6">
              {t("para.para_03")}{" "}
            </p>
            <div className="ml-[20px]">
              <ol style={{ listStyle: "decimal" }}>
                <li>
                  <p className="mt-2 text-[16px] text-emerald-950 text-justify  leading-6">
                    {t("para.para_04")}
                  </p>
                </li>
                <li>
                  <p className="mt-2 text-[16px] text-emerald-950 text-justify  leading-6">
                    {t("para.para_05")}
                  </p>
                </li>
                <li>
                  <p className="mt-2 text-[16px] text-emerald-950 text-justify  leading-6">
                    {t("para.para_06")}
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div className="flex gap-4 md:flex-row flex-col w-[100%]">
            <div className="flex flex-col mt-16 xs:w-[100%] md:w-[40%]">
              {/* Date */}
              <div className="flex flex-col gap-2">
                <h4 className="text-[16px] text-emerald-950 font-[500]">
                  {t("main.header_06")}
                </h4>
                <div className="flex gap-3">
                  <p className="text-[14px] font-[500] text-gray-500">
                    {t("date.date_02")}
                  </p>
                  <BsFillCalendarPlusFill className="text-gray-500" />
                </div>
              </div>

              {/* Location */}
              <div className="flex flex-col gap-2 mt-10">
                <h4 className="text-[16px] text-emerald-950 font-[500]">
                  {t("main.header_08")}
                </h4>
                <div className="">
                  <p className="text-[14px]  font-[500] text-gray-500">
                    {t("address.address_01")}
                  </p>
                  <p className="text-[14px] font-[500] text-gray-500 w-[60%]">
                    {t("address.address_02")}
                  </p>
                </div>
              </div>

              {/* connect  */}
              <div>
                <div
                  className="sm:ml-[-10px] flex gap-2 items-center mt-10 cursor-pointer"
                  onClick={toggleShowInput}
                >
                  <img src={whatsappIcon} className="w-[35px] largeTofix" />
                  <h4 className="text-[14px] text-emerald-950 w-[165px] font-[400] underline">
                    {t("main.header_12")}
                  </h4>
                </div>

                {showInput && (
                  <>
                    <div className="flex mt-4 gap-2">
                      <input
                        type="text"
                        placeholder="Enter code"
                        className="h-[30px] w-[45%] border-blue-100 p-2 rounded"
                        style={{ border: "0.5px solid #454" }}
                        value={code}
                        onChange={handleChange}
                        maxLength={10}
                      />
                      <button onClick={handleSubmit}>
                        <img src={tick} className="w-[20px]" />
                      </button>
                    </div>
                    {error && (
                      <div>
                        <p className="text-[12px] text-red-800">{t(error)}</p>
                      </div>
                    )}
                  </>
                )}

                <div
                  className="sm:ml-[-10px] flex gap-2 items-center mt-10 cursor-pointer "
                  onClick={handleOpen1}
                >
                  <img src={rating} className="w-[35px] largeTofix" />
                  <h4 className="text-[14px] text-emerald-950 w-[165px] font-[400] underline">
                    {t("main.header_13")}
                  </h4>
                </div>
              </div>
            </div>
            {/* Map */}
            <div className="flex flex-1  flex-col mt-8 md:mt-16">
              <div className="flex flex-col gap-4">
                <div className="w-[100%]  h-[250px] md:h-[300px] rounded-md overflow-hidden shadow-md">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3632.122255395675!2d54.43446827489329!3d24.44654336169626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e42834cf5a8d9%3A0x402184736323bda0!2sAnantara%20Eastern%20Mangroves%20Abu%20Dhabi%20Hotel!5e0!3m2!1sen!2sin!4v1698660761664!5m2!1sen!2sin"
                    // width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="w-[100%]"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Marquee */}

        <div className="mt-5">
          <div className="flex items-center mb-[-5px] ">
            <div className="h-[1px] bg-[#0F4450] w-[10%]"></div>
            <div className=" mx-2 flex items-center justify-center">
              <span className="text-[10px] sm:text-[12px] event_sponsor">
                {t("main.header_07")}
              </span>
            </div>
            <div className="h-[1px] bg-[#0F4450] w-[100%]"></div>
          </div>

          <div className="w-[100%]">
            <Marquee pauseOnHover={true}>
              <div className="flex items-center justify-evenly gap-[65px] ">
                <div className="w-[100px] h-[100%]">
                  <img src={imgGpng} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[80px] h-[100%]">
                  <img src={imgB} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={imgF} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[90px] h-[100%]">
                  <img src={imgA} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[80px] h-[100%]">
                  <img src={imgH} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[200px] h-[100%]">
                  <img src={imgC} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[150px] h-[100%]">
                  <img src={imgG} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[150px] h-[100%]">
                  <img src={imgI} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[150px] h-[100%] mr-10">
                  <img src={imgJ} alt="" className="w-[100%] object-cover" />
                </div>
              </div>
            </Marquee>
          </div>

          <div className="">
            <div className="h-[0.5px] bg-[#0F4450] w-[100%]"></div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Overview;
