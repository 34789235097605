import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { aboutImage } from "../assets/data";

import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";

const About = () => {
  const { t } = useTranslation();

  const eventLinkstyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    border: "0.3px solid #a0a0a0",
  };

  const navLinks = [
    {
      id: 1,
      name: t("navlink.events"),
      route: "allEvent",
      className: "",
      style: eventLinkstyle,
      parentClassName: "flex-1",
      children: [
        {
          id: "wire",
          name: "WIRE",
          route: "",
          style: eventLinkstyle,
        },
        {
          id: "sama",
          name: "SAMA",
          route: "samaEvent",
          style: eventLinkstyle,
        },
      ],
    },
    {
      id: 2,
      name: t("navlink.about"),
      route: "about",
    },
    {
      id: 3,
      name: t("navlink.contacts"),
      route: "contacts",
    },
    {
      id: 4,
      name: t("navlink.admin_portal"),
      route: "admin_portal",
    },
  ];
  return (
    <div className="flex flex-col gap-2 h-screen w-[100%]  px-8 ">
      <Header title={t("main.header_01")} />
      <Navbar navLinks={navLinks} />

      <div className="flex flex-col md:flex-row flex-6 w-[100%] mt-4 gap-[50px] overflow-y-auto">
        <div className=" w-9/12 sm:w-1/2 md:w-5/12">
          <img
            src={aboutImage}
            alt="about Image"
            className="w-full h-full object-contain"
          />
        </div>

        <div className="md:w-11/12 lg:w-6/12 mb-5 mt-10">
          <h1 className="text-[35px] font-[600] text-right mb-8">
            {t("main.header_09")}
          </h1>
          <p className="text-[16px] text-justify  leading-8">
            {t("para.para_07")}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
