import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import { useSearchParams } from "react-router-dom";

import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import PhotoSectionE2 from "../../components/PhotoSectionEventTwo";

const E2Photos = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const e2 = Boolean(searchParams.get("e2"));

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "sama23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "sama23/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "sama23/documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "sama23/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "sama23/photos",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%]  px-8 overflow-y-auto">
      <Header title={t("main.header_02_02")} />
      <Navbar navLinks={navLinks} />
      <div className="relative flex justify-center items-center">
        <PhotoSectionE2 />
      </div>
      <Footer />
    </div>
  );
};

export default E2Photos;
