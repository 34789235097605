import React, { useContext, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageDropdown from "./LanguageDropdown";
import { AppContext } from "../contexts/appContext";

const Navbar = ({ navLinks }) => {
  const { sideMenuOpen, setSideMenuOpen } = useContext(AppContext);
  const route = useLocation().pathname.substring(1);

  const sidebarRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    let handler = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSideMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleClick = (link) => {
    navigate(`/${link.route}`);
    setSideMenuOpen(false);
  };

  return (
    <>
      {/* navbar menu */}

      <div className="hidden md:block border-y-[1px] border-black py-1 items-center justify-center">
        <ul className="flex lg:justify-around items-center">
          {navLinks.map((link, index) => (
            <li
              key={link.id}
              className={`${link?.parentClassName} py-0 mx-5 lg:mx-8`}
            >
              {link?.name &&
                (link?.children ? (
                  <div className="flex gap-5 ">
                    <a
                      style={link?.style}
                      className={` lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                        route === link.route?.split("?")[0]
                          ? "activeNav"
                          : "whileHoverOnNav"
                      } `}
                      onClick={() => handleClick(link)}
                    >
                      {link.name}
                    </a>
                    {link?.children?.map((childLink, ind) => (
                      <a
                        style={childLink?.style}
                        key={ind}
                        className={` lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                          route === childLink.route?.split("?")[0]
                            ? "activeNav"
                            : "whileHoverOnNav"
                        } `}
                        onClick={() => handleClick(childLink)}
                      >
                        {childLink.name}
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="flex gap-0">
                    <a
                      style={link?.style}
                      className={` lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                        route === link.route?.split("?")[0]
                          ? "activeNav"
                          : "whileHoverOnNav"
                      } `}
                      onClick={() => handleClick(link)}
                    >
                      {link.name}
                    </a>

                    {link?.badge && link?.badge()}
                  </div>
                ))}
              {link.icon && (
                <div
                  className={`w-fit ${
                    route === link.route?.split("?")[0]
                      ? "bg-emerald-950 text-white"
                      : ""
                  } mx-10 px-5  whitespace-nowrap transition-all cursor-pointer rounded-sm hover:bg-emerald-950 hover:text-white font-[600] uppercase text-sm  no-underline`}
                  onClick={() => handleClick(link)}
                >
                  {link.icon}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Sidebar menu */}

      <div
        ref={sidebarRef}
        className={`md:hidden absolute ${
          sideMenuOpen ? "right-0 fixed top-0" : "hidden"
        } w-[80%] h-full shadow-md p-3 bg-[#f1f1f1] transition-all delay-2000 z-[999]`}
      >
        <div
          onClick={() => setSideMenuOpen(false)}
          className="w-[40px] h-[40px] rounded-full bg-gray-700 flex items-center justify-center absolute top-[10px] cursor-pointer  hover:opacity-[.8] hover:text-[#f1f1f1] z-[2] right-[20px]"
        >
          <AiOutlineClose size={20} className="font-700 text-white" />
        </div>

        <ul className="flex flex-col gap-5 mt-[100px] items-center">
          {navLinks.map((link) => (
            <li key={link.id} className={`${link?.parentClassName} py-2`}>
              {link?.name &&
                (link?.children ? (
                  <div className="flex flex-col gap-10">
                    <a
                      style={link?.style}
                      className={` lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                        route === link.route?.split("?")[0]
                          ? "activeNav"
                          : "whileHoverOnNav"
                      } `}
                      onClick={() => handleClick(link)}
                    >
                      {link.name}
                    </a>
                    {link?.children?.map((childLink, ind) => (
                      <a
                        style={link?.style}
                        key={ind}
                        className={` lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                          route === childLink.route?.split("?")[0]
                            ? "activeNav"
                            : "whileHoverOnNav"
                        } `}
                        onClick={() => handleClick(childLink)}
                      >
                        {childLink.name}
                      </a>
                    ))}
                  </div>
                ) : (
                  <a
                    style={link?.style}
                    className={`flex gap-1 items-center  lg:p-1 whitespace-nowrap cursor-pointer rounded-sm font-[400] capitalize text-md  no-underline ${
                      route === link.route?.split("?")[0]
                        ? "activeNav"
                        : "whileHoverOnNav"
                    } `}
                    onClick={() => handleClick(link)}
                  >
                    {link?.badge && link?.badge()} {link.name}
                  </a>
                ))}
              {link.icon && (
                <div
                  className={`w-fit ${
                    route === link.route?.split("?")[0]
                      ? "bg-gray-700 text-white"
                      : ""
                  } mx-10 px-5  whitespace-nowrap transition-all cursor-pointer rounded-sm hover:bg-gray-700 hover:text-white font-[600] uppercase text-sm  no-underline`}
                  onClick={() => handleClick(link)}
                >
                  {link.icon}
                </div>
              )}
            </li>
          ))}
        </ul>

        <div className="md:hidden absolute top-[10px] left-[20px] -3">
          <LanguageDropdown />
        </div>
      </div>
    </>
  );
};

export default Navbar;
