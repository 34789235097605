import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";

import { card01, pdfImg } from "../../assets/data";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CommonHeading from "../../components/CommonHeading";
import CardOne from "../../components/Card/CardOne";

import draftAgendaQr from "../../assets/W24qr/draftAgendaqr.png";
import draftAgendaFrQr from "../../assets/W24qr/draftAgendarFrQr.png";
import combinedAgendaQr from "../../assets/W24qr/combinedAgendaQr.png";
import workingGroupQr from "../../assets/W24qr/workingGroup.png";
import newGif from "../../assets/img/newGif.gif";

const E3Agenda = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire24",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "wire24/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "wire24/documents",
    },
    {
      id: "presentatation",
      name: t("navlink.presentation"),
      route: "wire24/presentation",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "wire24/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "wire24/photos",
      badge: () => (
        <div style={{ width: "30px", height: "30px" }}>
          <img src={newGif} style={{ objectFit: "contain", width: "100px" }} />
        </div>
      ),
    },
  ];

  const agendaArr = [
    {
      id: 1,
      img: card01,
      title: t("card.header_01_w24"),
      subtitle: t("card.sub_header_01_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/DraftAgenda_WIRE_EN.pdf",
      downloadImg: pdfImg,
      qr: draftAgendaQr,
      cardtext: t("card.header_01_w24"),
      downloadFileName: "DraftAgenda_WIRE_EN.pdf",
    },
    {
      id: 2,
      img: card01,
      title: t("card.header_03_w24"),
      subtitle: t("card.sub_header_03_w24"),
      uploaded: t("card.uploaded_05_w24"),
      href: "https://wildex.ihiapps.com/wire_24/DraftAgenda_WIRE_FR.pdf",
      downloadImg: pdfImg,
      qr: draftAgendaFrQr,
      cardtext: t("card.header_03_w24"),
      downloadFileName: "DraftAgenda_WIRE_FR.pdf",
    },

    {
      id: 3,
      img: card01,
      title: t("card.header_04_w24"),
      subtitle: t("card.sub_header_02_w24"),
      uploaded: t("card.uploaded_04_w24"),
      href: "https://wildex.ihiapps.com/wire_24/CombinedAgendaWIRE24.pdf",
      downloadImg: pdfImg,
      qr: combinedAgendaQr,
      cardtext: t("card.header_04_w24"),
      downloadFileName: "CombinedAgendaWIRE24.pdf",
    },

    {
      id: 4,
      img: card01,
      title: t("card.header_05_w24"),
      subtitle: t("card.sub_header_05_w24"),
      uploaded: t("card.uploaded_06_w24"),
      href: "https://wildex.ihiapps.com/wire_24/Working_groups_schedule.pdf",
      downloadImg: pdfImg,
      qr: workingGroupQr,
      cardtext: t("card.header_05_w24"),
      downloadFileName: "Working_groups_schedule.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02_03")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_03")}</CommonHeading>
        <div
          style={{ paddingBottom: 50 }}
          className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]"
        >
          {agendaArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
          {agendaArr.length === 0 && <h6>No documents available</h6>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default E3Agenda;
