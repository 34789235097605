import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { BsFillCalendarPlusFill } from "react-icons/bs";
import {
  closeIcon,
  evaluation2,
  evaluation3,
  imgA,
  imgGpng,
  qrwhatsapp,
  imgB,
  imgF,
  imgI,
} from "../../assets/data";
import mq1 from "../../assets/img/marqueeWire24/imgpsh_fullsize_anim (14).png";
import mq2 from "../../assets/img/marqueeWire24/imgpsh_fullsize_anim (15).png";
import mq3 from "../../assets/img/marqueeWire24/imgpsh_fullsize_anim (16).png";
import mq4 from "../../assets/img/marqueeWire24/imgpsh_fullsize_anim (17).png";
import mq5 from "../../assets/img/marqueeWire24/imgpsh_fullsize_anim (18).png";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import { AiOutlineHome } from "react-icons/ai";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import Modal from "../../components/Modal";
import CommonHeading from "../../components/CommonHeading";

import wire24DynamicGender from "../../assets/img/wire24DynamicGender.png";
import newGif from "../../assets/img/newGif.gif";

const E3Overview = () => {
  const { t } = useTranslation();
  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire24",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "wire24/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "wire24/documents",
    },
    {
      id: "presentatation",
      name: t("navlink.presentation"),
      route: "wire24/presentation",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "wire24/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "wire24/photos",
      badge: () => (
        <div style={{ width: "30px", height: "30px" }}>
          <img src={newGif} style={{ objectFit: "contain", width: "100px" }} />
        </div>
      ),
    },
  ];

  const [showInput, setShowInput] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOpen1 = () => {
    setOpen1(!open1);
  };

  const handleOpen2 = () => {
    setOpen2(!open2);
  };

  const toggleShowInput = () => {
    setShowInput(!showInput);
    setCode("");
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setCode(val);
    setError("");
  };

  const handleSubmit = () => {
    if (!code) {
      return setError("Please enter code");
    } else if (code === "SAMA2023") {
      handleOpen();
    } else {
      return setError("Incorrect code");
    }
  };

  return (
    <>
      <Modal open={open}>
        <div className="relative">
          <div
            className="absolute top-1.5 right-1.5"
            onClick={() => {
              handleOpen();
              toggleShowInput();
            }}
          >
            <img
              src={closeIcon}
              className="w-[40px]"
              style={{ filter: "invert(100%)" }}
            />
          </div>
          <img src={qrwhatsapp} />
          <div className="absolute bottom-2 w-[100%] flex items-center justify-center">
            <a
              href="https://chat.whatsapp.com/CWKvhb6u6Vd7EU0pUcNWGs"
              target="_blank"
              className="text-[14px] mt-[4px] text-blue-700 hover:underline font-[700]"
            >
              {t("click_here")}
            </a>
          </div>
        </div>
      </Modal>

      <Modal open={open1}>
        <div className="relative">
          <div
            className="absolute top-1.5 right-1.5"
            onClick={() => {
              handleOpen1();
            }}
          >
            <img
              src={closeIcon}
              className="w-[30px]"
              style={{ filter: "invert(100%)" }}
            />
          </div>
          <img src={evaluation2} className="w-[450px]" />
          <div className="absolute bottom-2 right-3 w-[100%] flex items-center justify-end">
            <a
              href="https://forms.office.com/e/ghGykcNShM"
              target="_blank"
              className="text-[14px] hover:underline center text-[#FFFFFF] "
            >
              {t("click_here")}
            </a>
          </div>
        </div>
      </Modal>

      <Modal open={open2}>
        <div className="relative">
          <div
            className="absolute top-1.5 right-1.5"
            onClick={() => {
              handleOpen2();
            }}
          >
            <img
              src={closeIcon}
              className="w-[30px]"
              style={{ filter: "invert(100%)" }}
            />
          </div>
          <img src={evaluation3} className="w-[450px]" />
          <div className="absolute bottom-2 right-3 w-[100%] flex items-center justify-end">
            <a
              href="https://forms.office.com/e/dc4eMBnbYL"
              target="_blank"
              className="text-[14px]  hover:underline center text-[#FFFFFF] "
            >
              {t("click_here")}
            </a>
          </div>
        </div>
      </Modal>

      <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%]  px-8 mb-10 sm:mb-0">
        <Header title={t("main.header_02_03")} />
        <Navbar navLinks={navLinks} />
        <div className="overflow-y-auto pb-5">
          <div className="grid md:grid-cols-1 lg:grid-cols-[1.3fr,1fr]  lg:gap-6  items-start mx-auto">
            {/* Overview */}
            <div className="flex flex-col gap-2">
              <CommonHeading>{t("main.header_05")}</CommonHeading>

              <p className="mt-2 text-[14px] text-emerald-950 text-justify  leading-6">
                {t("para.para_03_03")}
              </p>

              <CommonHeading>{t("main.header_05_02")}</CommonHeading>

              <p className="mt-2 text-[14px] text-emerald-950 text-justify  leading-6">
                {t("para.para_04_03")}
              </p>
            </div>

            <div className="flex gap-6 md:flex-row flex-col w-[100%]">
              <div
                style={{ display: "none" }}
                className="flex flex-col mt-3 xs:w-[100%] md:w-[40%]"
              >
                <div className="relative bg-white shadow-lg shadow-black/20 border-2 p-4 pt-1 pb-1 rounded-2xl ">
                  <div className="flex gap-1 items-center">
                    <div>
                      <img
                        src={newGif}
                        style={{ objectFit: "contain", width: "100px" }}
                      />
                    </div>
                    <h4 className="text-[12px] text-emerald-950 font-[500]">
                      {t("main.exploreGender")}
                    </h4>
                  </div>
                  <p className="text-[10px] text-gray-500">
                    {t("main.exploreGenderDesc")}
                  </p>
                  <div className="flex gap-1 mt-1 mb-1">
                    <h4 className="text-[12px] text-emerald-950 font-[500]">
                      {t("main.exploreGenderFR")}
                    </h4>
                  </div>
                  <p className="text-[10px] text-gray-500">
                    {t("main.exploreGenderDescFR")}
                  </p>
                  <div className="flex mt-2 gap-2">
                    <div className=" h-28 w-28  flex justify-center align-middle">
                      <img alt="cardImage" src={wire24DynamicGender} />
                    </div>
                    <div className="flex-1 flex flex-col justify-around">
                      <div>
                        <div>
                          <p className="text-[10px]">Deadline for responses</p>
                          <p className="text-[10px] text-red-500">
                            30 Oct 2024
                          </p>
                        </div>
                        <div>
                          <p className="text-[10px]">Date limite de réponse</p>
                          <p className="text-[10px] text-red-500">
                            30 Oct 2024
                          </p>
                        </div>
                      </div>

                      <p className="text-[10px] border-t-[1px] pt-1">
                        <a
                          target="_blank"
                          className="mr-2 text-[10px] text-blue-500"
                          href="https://forms.office.com/e/uBTmQj8xbT"
                        >
                          {t("main.exploreGenFormLink1")}
                        </a>
                        {t("main.exploreGenFormLink2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Map */}
              <div className="flex flex-1  flex-col mt-8 md:mt-2">
                <div className="flex gap-2">
                  <div className="flex-1 flex flex-col gap-2">
                    <h4 className="text-[14px] text-emerald-950 font-[500]">
                      {t("main.header_06")}
                    </h4>
                    <div className="flex gap-1">
                      <p className="text-[12px] font-[400] text-gray-500">
                        {t("date.date_03")}
                      </p>
                      <BsFillCalendarPlusFill className="text-gray-500" />
                    </div>
                  </div>

                  <div className="flex-1 flex flex-col gap-2">
                    <h4 className="text-[14px] text-emerald-950 font-[500]">
                      {t("main.header_08")}
                    </h4>
                    <div>
                      <p className="text-[12px] font-[400] text-gray-500 w-[100%]">
                        {t("address.address_02_03")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 mt-5">
                  <div className="w-[100%]  h-[250px] md:h-[300px] rounded-md overflow-hidden shadow-md">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13243.06694918784!2d18.403631582314507!3d-33.92140261773427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc6763f918b23b%3A0xa4eabd1c1805e294!2sSouthern%20Sun%20Cape%20Sun!5e0!3m2!1sen!2sin!4v1727958613137!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      className="w-[100%]"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Marquee */}
        <div className="mt-5">
          <div className="flex items-center mb-[-5px] ">
            <div className="h-[1px] bg-[#0F4450] w-[10%]"></div>
            <div className=" mx-2 flex items-center justify-center">
              <span className="text-[10px] sm:text-[12px] event_sponsor">
                {t("main.header_07")}
              </span>
            </div>
            <div className="h-[1px] bg-[#0F4450] w-[100%]"></div>
          </div>

          <div className="w-[100%]">
            <Marquee pauseOnHover={true}>
              <div className="flex items-center justify-evenly gap-[65px] ">
                <div className="w-[150px] h-[100%] ml-5">
                  <img src={mq1} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={mq2} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={mq3} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[200px] h-[100%]">
                  <img src={mq4} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={mq5} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={imgGpng} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[80px] h-[100%]">
                  <img src={imgB} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[100px] h-[100%]">
                  <img src={imgF} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[90px] h-[100%]">
                  <img src={imgA} alt="" className="w-[100%] object-cover" />
                </div>

                <div className="w-[150px] h-[100%]">
                  <img src={imgI} alt="" className="w-[100%] object-cover" />
                </div>
              </div>
            </Marquee>
          </div>

          <div className="">
            <div className="h-[0.5px] bg-[#0F4450] w-[100%]"></div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default E3Overview;
