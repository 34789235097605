import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import newGif from "../../assets/img/newGif.gif";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";

const E3Presentation = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire24",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "wire24/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "wire24/documents",
    },
    {
      id: "presentatation",
      name: t("navlink.presentation"),
      route: "wire24/presentation",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "wire24/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "wire24/photos",
      badge: () => (
        <div style={{ width: "30px", height: "30px" }}>
          <img src={newGif} style={{ objectFit: "contain", width: "100px" }} />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%]  px-8 overflow-y-auto">
      <Header title={t("main.header_02_03")} />
      <Navbar navLinks={navLinks} />
      <div className="flex justify-center items-center h-80">
        <h1> Coming soon</h1>
      </div>
      <Footer />
    </div>
  );
};

export default E3Presentation;
