import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = ({ title }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="py-2">
      <h1 className="font-[600] tracking-wider rounded-xl inline-block p-3 font-Inter  text-2xl sm:text-3xl md:text-4xl capitalize text-emerald-950 drop-shadow-md">
        {title}
      </h1>
    </div>
  );
};

export default Header;
