import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";

const E2Contacts = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "sama23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "sama23/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "sama23/documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "sama23/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "sama23/photos",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%] px-8 ">
      <Header title={t("main.header_02_02")} />
      <Navbar navLinks={navLinks} />
      <div className="overflow-y-auto">
        <div className="flex flex-col gap-5 lg:flex-row justify-around h-full md:mt-7 lg:mt-14">
          <div className="flex flex-col">
            <h1 className="text-[25px] text-[#01410b] md:text-[30px] lg:text-[35px] font-[600] text-left">
              {t("main.header_10")}
            </h1>
            <div className="flex flex-col gap-2">
              <p className="text-[16px] text-justify  leading-8">
                {t("contact.line_001")}
              </p>
              <div className="flex md:flex-row flex-col w-[100%] md:gap-10 gap-6 my-3 lg:my-5">
                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#7986cb",
                  }}
                >
                  <p className="text-[18px]   leading-8 font-[600] lg:mb-1">
                    {t("Ms. Folusho Ajayi Adelekan")}
                  </p>
                  <a
                    href="mailto:folusho.ajayi@un.org"
                    className="text-[16px]  text-blue-600"
                  >
                    {t("folusho.ajayi@un.org")}
                  </a>

                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("National Programme Officer")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("United Nations Office on Drugs and Crime")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("Country Office Nigeria")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("Mobile: +234 802 440 0921")}
                    </p>
                  </div>
                </div>

                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#00acc1",
                  }}
                >
                  <p className="text-[18px]   leading-8 font-[600] lg:mb-1">
                    {t("Ms. Fatoumata Diaw")}
                  </p>
                  <a
                    href="mailto:fatoumata.diaw@un.org"
                    className="text-[16px]  text-blue-600"
                  >
                    {t("fatoumata.diaw@un.org")}
                  </a>
                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("Programme Assistant")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("Central and West Africa")}
                    </p>
                  </div>
                </div>
                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#8ec375",
                  }}
                >
                  <p className="text-[18px]   leading-8 font-[600] lg:mb-1">
                    {t("Mr. Tshifhiwa Mukumela")}
                  </p>
                  <a
                    href="mailto:tshifhiwa.mukumela@un.org"
                    className="text-[16px]  text-blue-600"
                  >
                    {t("tshifhiwa.mukumela@un.org")}
                  </a>
                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("Programme and Administrative Assistant")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("Southern Africa")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("Tel: +27 12 432 0837/+27 71 375 6541")}
                    </p>
                  </div>
                </div>
                <div
                  className="border-2 p-4 rounded-xl bg-[#ffffff]"
                  style={{
                    borderWidth: "1px",
                    borderTopWidth: "12px",
                    borderColor: "#ff627f",
                  }}
                >
                  <p className="text-[18px]   leading-8 font-[600] lg:mb-1">
                    {t("Ms. Violet Khejeri")}
                  </p>
                  <a
                    href="mailto:violet.khejeri@un.org"
                    className="text-[16px]  text-blue-600"
                  >
                    {t("violet.khejeri@un.org")}
                  </a>
                  <div>
                    <p className="text-[14px]   leading-7">
                      {t("Programme Management Assistant")}
                    </p>
                    <p className="text-[14px]   leading-7">
                      {t("East Africa")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default E2Contacts;
