import i18next from "i18next";
import React, { useState } from "react";
import { LuLanguages } from "react-icons/lu";
import { languageIcon } from "../assets/data";
const languages = [
  {
    language: "English",
    id: "en",
  },
  {
    language: "French",
    id: "fr",
  },
  {
    language: "Spanish",
    id: "es",
  },
];

const LanguageDropdown = () => {
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    JSON.parse(localStorage.getItem("language"))
  );

  const handleLanguage = (item) => {
    setSelectedLanguage(item.language);
    localStorage.setItem("language", JSON.stringify(item.language));
    i18next.changeLanguage(item.id);
    setOpen(false);
  };
  return (
    <div className="relative items-center z-50 w-[100px]">
      {/* <LuLanguages
        size={25}
        className="cursor-pointer"
        onClick={() => setOpen(!open)}
      /> */}
      <img
        src={languageIcon}
        alt=""
        className="w-[40px] cursor-pointer"
        onClick={() => setOpen(!open)}
      />
      <h4 onClick={() => setOpen(!open)} className="text-[10px] cursor-pointer">
        {selectedLanguage}
      </h4>
      {open && (
        <div className="absoute right-[0px] bottom-[-3px] shadow-md rounded-md p-2 bg-white z-30">
          <ul>
            {languages.map((item, index) => (
              <li
                key={index}
                onClick={() => handleLanguage(item)}
                className={`text-xs cursor-pointer font-medium px-2 hover:bg-slate-200 rounded-md ${
                  index === 1 ? "my-2" : ""
                }`}
              >
                {item.language}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
