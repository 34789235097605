import React from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
const imageOBJ = importAll(
  require.context("../assets/img/wire23", false, /\.(png|jpe?g|svg|JPG)$/)
);

const images = Object.keys(imageOBJ).map((k) => ({
  original: imageOBJ[k],
  thumbnail: imageOBJ[k],
  originalHeight: 600,
  originalWidth: 1000,
}));

const PhotoSection = () => {
  return (
    <div className="w-[100%] sm:w-2/4">
      <ImageGallery items={images} lazyLoad={true} />
    </div>
  );
};

export default PhotoSection;
