import React from "react";
import { BsDownload } from "react-icons/bs";

const CardOne = ({ item }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(255,255,255,0.9)",
        boxShadow: "3px 3px 10px rgba(0,0,0,0.5)",
      }}
      className="border-2 relative p-3 pb-0 w-[230px]   rounded-lg  overflow-hidden  box-1 z-50"
    >
      <div className="box-2 z-50 flex flex-col items-center justify-center gap-5 p-4 ">
        <p className="text-lg text-center text-gray-200">{item?.cardtext}</p>
        <div className="w-[80px] h-[80px]">
          <img
            src={item?.downloadImg}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>
        <button className="border-[1.5px] p-[5px] rounded-[5px] border-white z-20 hover:scale-105 transition-all delay-100">
          <a
            href={item?.href}
            target="_blank"
            download={item?.downloadFileName}
            className="flex items-center gap-2 "
          >
            <BsDownload className="text-white" />
            <span className="text-xs text-white">Download PDF</span>
          </a>
        </button>
      </div>

      <div className="relative w-[100%] h-[280px]">
        <div className="w-[100%] h-[100%] shadow-xl rounded-md overflow-hidden">
          <img src={item?.img} alt="" className="w-[100%] h-[100%]" />
        </div>
        {!item?.slefImg && (
          <div className="absolute w-full h-full -top-3 p-4 flex flex-col justify-center">
            <h5 className="text-emerald-950 font-[400] text-xl mt-0">
              {item?.title}
            </h5>
            <h6 className="text-emerald-950 font-[300] text-md mt-2">
              {item?.subtitle}
            </h6>
          </div>
        )}
      </div>
      <div className="flex gap-1 items-center justify-between mt-1">
        <div className="flex-1 flex flex-col justify-between">
          <h3 className="capitalize font-[500] text-sm text-emerald-950">
            {item?.title}
          </h3>
          <p className=" text-[11px] font-[400] text-gray-500">
            {item?.subtitle}
          </p>
          <p className="text-[11px] font-[400] text-gray-500 mt-2">
            {item?.uploaded}
          </p>
        </div>
        <div className="w-[75px] h-[100px]">
          <img src={item?.qr} alt="" className="w-full h-full object-contain" />
        </div>
      </div>
    </div>
  );
};

export default CardOne;
