import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import newGif from "../../assets/img/newGif.gif";

const E3Contacts = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const e2 = Boolean(searchParams.get("e2"));

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire24",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "wire24/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "wire24/documents",
    },
    {
      id: "presentatation",
      name: t("navlink.presentation"),
      route: "wire24/presentation",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "wire24/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "wire24/photos",
      badge: () => (
        <div style={{ width: "30px", height: "30px" }}>
          <img src={newGif} style={{ objectFit: "contain", width: "100px" }} />
        </div>
      ),
    },
  ];

  const contactsData = [
    {
      name: "Ms. Violet Khejeri",
      email: "violet.khejeri@un.org",
      detail: "Kenya, Madagascar, Tanzania, Uganda",
      city: "",
      mobile: "",
    },
    {
      name: "Mr. Tshifiwa Mukumela",
      email: "tshifhiwa.mukumela@un.org",
      detail: "Angola, Malawi, Mozambique, South Africa",
      city: "",
      mobile: "",
    },
    {
      name: "Ms. Fatoumata Diaw",
      email: "fatoumata.diaw@un.org",
      detail:
        "Cameroon, Central African Republic,  Congo/Brazaville, DRC, Gabon, Senegal",
      city: "",
      mobile: "",
    },
    {
      name: "Mr. Amilcar Revello",
      email: "amilcar.revello@un.org",
      detail: "Mexico",
      city: "",
      mobile: "",
    },
    {
      name: "Ms. Nur Asmayanti Binti Kamaruddin",
      email: "nur.kamaruddin@un.org",
      detail:
        "Cambodia, China, HK China, India, Indonesia, Lao PDR, Malaysia, Nepal, Philippines, Thailand, Viet Nam",
      city: "",
      mobile: "",
    },
    {
      name: "Ms. Folusho Ajayi Adeleka",
      email: "folusho.ajayi@un.org",
      detail: "Nigeria",
      city: "",
      mobile: "",
    },
    {
      name: "Ms. Cecile Steverlynck",
      email: "steverlynck@un.org",
      detail: "Italy, Portugal, Spain",
      city: "",
      mobile: "",
    },
  ];

  const colors = [
    "#7986cb",
    "#9DB67A",
    "#05ACC1",
    "#F87171",
    "#D6C0B3",
    "#CDC1FF",
    "#FCDC94",
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%] px-8 ">
      <Header title={t("main.header_02_03")} />
      <Navbar navLinks={navLinks} />
      <div className="overflow-y-auto">
        <div className="flex flex-col gap-5 lg:flex-row justify-around h-full md:mt-7 lg:mt-6">
          <div className="flex flex-col">
            <h1 className="text-[25px] text-[#01410b] md:text-[30px] lg:text-[35px] font-[600] text-left">
              {t("main.header_10")}
            </h1>
            <div className="flex flex-col gap-2 pb-5">
              <p className="text-[16px] font-[300] leading-8">
                {t("contact.w24.contactLine01")}
                <a
                  className="pl-1 text-blue-600"
                  href="mailto:unodc-wire@un.org"
                >
                  unodc-wire@un.org
                </a>
              </p>
              <p className="text-[16px]  font-[300]  leading-8">
                {t("contact.w24.contactLine02")}
              </p>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
                {contactsData.map((ele, index) => (
                  <div
                    key={index}
                    className="border-2 p-4 rounded-xl bg-[#ffffff]"
                    style={{
                      borderWidth: "1px",
                      borderTopWidth: "12px",
                      borderColor: colors[index],
                    }}
                  >
                    <p className="text-[18px]   leading-8 font-[400] lg:mb-1">
                      {ele.name}
                    </p>
                    <a
                      href={`mailto:${ele.email}`}
                      className="text-[16px] break-all  text-blue-600"
                    >
                      {ele.email}
                    </a>
                    <div>
                      <p className="text-[14px] font-[300]   leading-7">
                        {ele.detail}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default E3Contacts;
