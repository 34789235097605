import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";

import { card01, pdfImg } from "../../assets/data";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CommonHeading from "../../components/CommonHeading";
import CardOne from "../../components/Card/CardOne";

// qr
import progrmAgendaEn from "../../assets/sama23Qr/Agenda_SAMA.png";
import progrmAgendaFr from "../../assets/sama23Qr/Agenda_SAMA_FR.png";

const E2Agenda = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "sama23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "sama23/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "sama23/documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "sama23/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "sama23/photos",
    },
  ];

  const agendaArr = [
    {
      id: 1,
      img: card01,
      title: t("card.header_01_e2"),
      subtitle: t("card.sub_header_01_e2"),
      uploaded: t("card.uploaded_01_e2"),
      href: "https://wildex.ihiapps.com/sama_23/Agenda_SAMA.pdf",
      downloadImg: pdfImg,
      qr: progrmAgendaEn,
      cardtext: t("card.header_01_e2"),
      downloadFileName: "SAMA23_EngAgenda.pdf",
    },
    {
      id: 11,
      img: card01,
      title: t("card.header_01_e2_fr"),
      subtitle: t("card.sub_header_01_e2_fr"),
      uploaded: t("card.uploaded_01_e2_fr"),
      href: "https://wildex.ihiapps.com/sama_23/Agenda_SAMA_FR.pdf",
      downloadImg: pdfImg,
      qr: progrmAgendaFr,
      cardtext: t("card.header_01_e2_fr"),
      downloadFileName: "SAMA23_FraAgenda.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02_02")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_03")}</CommonHeading>
        <div className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]">
          {agendaArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default E2Agenda;
