import React from "react";

const Modal = ({ open, onClose, children }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div
      className="fixed h-screen w-screen flex  justify-center items-center"
      style={{
        display: open ? "flex" : "none",
        zIndex: 1200,
        backgroundColor: "rgba(0,0,0,0.4)",
      }}>
      <div>{children}</div>
    </div>
  );
};

export default Modal;
