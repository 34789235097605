import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { imgd3, imgd4, pdfImg, card02 } from "../../assets/data";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CommonHeading from "../../components/CommonHeading";
import CardOne from "../../components/Card/CardOne";

// qr
import finalReportQr from "../../assets/W23qr/WIRE_Final_Report.png";
import wireMappingBilateralQr from "../../assets/W23qr/WIRE_Mapping_Bilaterals_Summary.png";
import wrkingGrpCompoQr from "../../assets/W23qr/WG_Composition.png";
import iccwcQr from "../../assets/W23qr/Indicator_Framework_e.png";
import wlfcAnalticTlktQr from "../../assets/W23qr/wire23Toolkit_e.png";
import wg1ReportingQr from "../../assets/W23qr/WG1_Reporting.png";
import wg2ProsecQr from "../../assets/W23qr/WG2_Prosecution.png";
import wg3PPTCustomQr from "../../assets/W23qr/WG3_Customs.png";

const Documents = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,

      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "wire23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "photos",
    },
  ];

  const documentArr = [
    {
      id: 0,
      img: card02,
      title: t("card.header_10"),
      uploaded: t("card.uploaded_10"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE_Final_Report.pdf",
      downloadImg: pdfImg,
      qr: finalReportQr,
      cardtext: t("card.header_10"),
      downloadFileName: "WIRE23_Final_Report.pdf",
    },
    {
      id: 1,
      img: card02,
      title: t("card.header_05"),
      uploaded: t("card.uploaded_05"),
      href: "https://wildex.ihiapps.com/wire_23/WIRE_Mapping_Bilaterals_Summary.pdf",
      downloadImg: pdfImg,
      qr: wireMappingBilateralQr,
      cardtext: t("card.header_05"),
      downloadFileName: "WIRE23_Mapping_Bilateral.pdf",
    },
    {
      id: 9,
      img: card02,
      title: t("card.header_09"),
      uploaded: t("card.uploaded_09"),
      href: "https://wildex.ihiapps.com/wire_23/WG_Composition.pdf",
      downloadImg: pdfImg,
      qr: wrkingGrpCompoQr,
      cardtext: t("card.header_09"),
      downloadFileName: "WIRE23_WG_Composition.pdf",
    },
    {
      slefImg: true,
      id: 3,
      img: imgd3,
      title: t("card.header_07"),
      uploaded: t("card.uploaded_07"),
      href: "https://wildex.ihiapps.com/wire_23/Indicator_Framework_e.pdf",
      downloadImg: pdfImg,
      qr: iccwcQr,
      cardtext: t("card.header_07"),
      downloadFileName: "ICCWC_Indicator_Framework.pdf",
    },
    {
      slefImg: true,
      id: 4,
      img: imgd4,
      title: t("card.header_08"),
      uploaded: t("card.uploaded_08"),
      href: "https://wildex.ihiapps.com/wire_23/Toolkit_e.pdf",
      downloadImg: pdfImg,
      qr: wlfcAnalticTlktQr,
      cardtext: t("card.header_08"),
      downloadFileName: "WLFC_Analytic_Toolkit.pdf",
    },
    {
      id: 5,
      img: card02,
      title: t("card.header_005"),
      uploaded: t("card.uploaded_005"),
      href: "https://wildex.ihiapps.com/wire_23/WG1_Reporting.pdf",
      downloadImg: pdfImg,
      qr: wg1ReportingQr,
      cardtext: t("card.header_005"),
      downloadFileName: "WG1_Reporting.pdf",
    },
    {
      id: 6,
      img: card02,
      title: t("card.header_006"),
      uploaded: t("card.uploaded_006"),
      href: "https://wildex.ihiapps.com/wire_23/WG2_Prosecution.pdf",
      downloadImg: pdfImg,
      qr: wg2ProsecQr,
      cardtext: t("card.header_006"),
      downloadFileName: "WG2_Prosecution.pdf",
    },
    {
      id: 7,
      img: card02,
      title: t("card.header_007"),
      uploaded: t("card.uploaded_007"),
      href: "https://wildex.ihiapps.com/wire_23/WG3_Customs.pdf",
      downloadImg: pdfImg,
      qr: wg3PPTCustomQr,
      cardtext: t("card.header_007"),
      downloadFileName: "WG3_Customs.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_04")}</CommonHeading>
        <div className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]">
          {documentArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Documents;
