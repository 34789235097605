import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { pdfImg, card02 } from "../../assets/data";
import { AiOutlineHome } from "react-icons/ai";
import Footer from "../../components/Footer";
import CommonHeading from "../../components/CommonHeading";
import CardOne from "../../components/Card/CardOne";

// qr
import actionPlanEnQr from "../../assets/sama23Qr/Annex_II_Action_Plan_ENG.png";
import actionPlanFrQr from "../../assets/sama23Qr/Annexe_II_Plan_d_Action_FR.png";
import conceptNotEnQr from "../../assets/sama23Qr/Annex_I_Concept_Note_ENG.png";
import conceptNotFrQr from "../../assets/sama23Qr/Annexe_I_Note_Conceptuelle_FR.png";

const E2Documents = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: 1,
      route: "",
      icon: <AiOutlineHome size={30} />,
      className: "",
      parentClassName: "flex-1",
    },
    {
      id: 2,
      name: t("navlink.overview"),
      route: "sama23",
    },
    {
      id: 3,
      name: t("navlink.agenda"),
      route: "sama23/agenda",
    },
    {
      id: 4,
      name: t("navlink.documents"),
      route: "sama23/documents",
    },
    {
      id: 5,
      name: t("navlink.contacts"),
      route: "sama23/event-contacts",
    },
    {
      id: 6,
      name: t("navlink.photos"),
      route: "sama23/photos",
    },
  ];

  const documentArr = [
    {
      id: 1,
      img: card02,
      title: t("card.header_02_e2"),
      subtitle: t("card.sub_header_02_e2"),
      uploaded: t("card.uploaded_02_e2"),
      href: "https://wildex.ihiapps.com/sama_23/Annex_II_Action_Plan_ENG.pdf",
      downloadImg: pdfImg,
      qr: actionPlanEnQr,
      cardtext: t("card.header_02_e2"),
      downloadFileName: "SAMA23_Action_Plan_Eng.pdf",
    },
    {
      id: 3,
      img: card02,
      title: t("card.header_03_e2"),
      subtitle: t("card.sub_header_03_e2"),
      uploaded: t("card.uploaded_03_e2"),
      href: "https://wildex.ihiapps.com/sama_23/Annexe_II_Plan_d_Action_FR.pdf",
      downloadImg: pdfImg,
      qr: actionPlanFrQr,
      cardtext: t("card.header_03_e2"),
      downloadFileName: "SAMA23_Action_Plan_Fra.pdf",
    },
    {
      id: 4,
      img: card02,
      title: t("card.header_04_e2"),
      subtitle: t("card.sub_header_04_e2"),
      uploaded: t("card.uploaded_03_e2"),
      href: "https://wildex.ihiapps.com/sama_23/Annex_I_Concept_Note_ENG.pdf",
      downloadImg: pdfImg,
      qr: conceptNotEnQr,
      cardtext: t("card.header_04_e2"),
      downloadFileName: "SAMA23_ConceptNote_En.pdf",
    },
    {
      id: 5,
      img: card02,
      title: t("card.header_05_e2"),
      subtitle: t("card.sub_header_05_e2"),
      uploaded: t("card.uploaded_05_e2"),
      href: "https://wildex.ihiapps.com/sama_23/Annexe_I_Note_Conceptuelle_FR.pdf",
      downloadImg: pdfImg,
      qr: conceptNotFrQr,
      cardtext: t("card.header_05_e2"),
      downloadFileName: "SAMA23_ConceptNote_Fra.pdf",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-full lg:h-screen w-[100%]  px-8">
      <Header title={t("main.header_02_02")} />

      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-6 overflow-y-auto">
        <CommonHeading>{t("main.header_04")}</CommonHeading>
        <div className="max-w-[99%] pb-4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[40px]">
          {documentArr.map((item, index) => (
            <CardOne key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default E2Documents;
