import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BsFillCalendarPlusFill } from "react-icons/bs";
import { samaImg } from "../assets/data";

const SamaEvent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div
        className="overflow-hidden ml-2 mt-4 transition ease-in-out delay-50 rounded-xl flex gap-2 hover:scale-105  relative bg-white w-full"
        style={{
          boxShadow: "3px 3px 10px rgba(0,0,0,0.3)",
          border: "1px solid #7986cb",
        }}
      >
        <div className="bg-[#7986cb] self-stretch w-[15px]"></div>
        <div className="flex-1 px-3 py-5 flex flex-col md:flex-row gap-5">
          <div className="w-[100%] md:w-[300px] md:h-[140px]">
            <img
              src={samaImg}
              alt="tiger"
              className="object-fill h-full w-full"
            />
          </div>
          <div className="flex flex-col gap-2  w-full">
            <div className="flex sm:flex-row gap-2 justify-between">
              <h4
                className="font-[500] text-[18px] capitalize text-sm cursor-pointer text-blue-900"
                onClick={() => navigate("/sama23")}
              >
                {t("main.header_02_02")}
              </h4>
              <span className="w-[20%]  px-1 py-0 text-center flex items-center rounded-lg text-[12px] text-gray-400 bg-slate-200 font-[500] min-w-[85px] h-[20px] justify-center">
                {t("button.button_01_e2")}
              </span>
            </div>
            <p className="text-[14px] font-[400] text-gray-500">
              {t("address.address_02_02")}
            </p>
            <div className="flex gap-3 mb-4 md:mt-4 items-center">
              <p className="text-[14px] font-[400] text-gray-500 ">
                {t("date.date_01")}
              </p>

              <BsFillCalendarPlusFill className="text-gray-500 cursor-pointer" />
            </div>
          </div>
          <span
            onClick={() => navigate("/sama23")}
            className="px-3 py-2  rounded-2xl absolute bottom-7 right-2 text-xs  text-white cursor-pointer bg-[#7986cb]"
          >
            {t("More details")}
          </span>
        </div>
      </div>
    </>
  );
};

export default SamaEvent;
