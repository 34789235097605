import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { mainContactImg } from "../assets/data";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const Contacts = () => {
  const { t } = useTranslation();

  const eventLinkstyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 90,
    border: "0.3px solid #a0a0a0",
  };

  const navLinks = [
    {
      id: 1,
      name: t("navlink.events"),
      route: "allEvent",
      className: "",
      style: eventLinkstyle,
      parentClassName: "flex-1",
      children: [
        {
          id: "wire",
          name: "WIRE",
          route: "",
          style: eventLinkstyle,
        },
        {
          id: "sama",
          name: "SAMA",
          route: "samaEvent",
          style: eventLinkstyle,
        },
      ],
    },
    {
      id: 2,
      name: t("navlink.about"),
      route: "about",
    },
    {
      id: 3,
      name: t("navlink.contacts"),
      route: "contacts",
    },
    {
      id: 4,
      name: t("navlink.admin_portal"),
      route: "admin_portal",
    },
  ];

  return (
    <div className="flex flex-col gap-2 h-screen lg:h-screen w-[100%] px-8 overflow-y-auto pb-10">
      <Header title={t("main.header_01")} />
      <Navbar navLinks={navLinks} />
      <div className="flex flex-col gap-5 lg:flex-row justify-around h-full md:mt-7 lg:mt-8">
        <div className="ml-3 sm:ml-10 flex flex-col">
          <h1 className="text-[25px] text-emerald-950 md:text-[30px] lg:text-[35px] font-[600] text-left mb-5">
            {t("main.header_10")}
          </h1>
          <div className="flex flex-col gap-2">
            <p className="text-[16px] text-emerald-950 text-justify  leading-8">
              {t("contact.line_001")}
            </p>
            <div className="flex md:flex-row flex-col w-[100%] md:gap-24 gap-6 my-3 lg:my-5">
              <div
                className="border-2 p-4 rounded-xl bg-[#ffffff]"
                style={{
                  borderWidth: "1px",
                  borderTopWidth: "12px",
                  borderColor: "#8ec375",
                }}
              >
                <p className="text-[18px]  text-justify  leading-8 font-[600] lg:mb-1">
                  {t("contact.address_01.line_01")}
                </p>
                <a
                  href="mailto:giovanni.broussard@un.org"
                  className="text-[16px] text-justify text-blue-600"
                >
                  {t("contact.address_01.line_02")}
                </a>
                <div>
                  <p className="text-[14px] text-emerald-950 text-justify  leading-7">
                    {t("contact.address_01.line_03")}
                  </p>
                  <p className="text-[14px] text-emerald-950 text-justify  leading-7">
                    {t("contact.address_01.line_04")}
                  </p>
                  <p className="text-[14px] text-emerald-950   leading-7">
                    {t("contact.address_01.line_05")}
                  </p>
                  <p className="text-[14px] text-emerald-950   leading-7">
                    {t("contact.address_01.line_06")}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[16px] text-emerald-950   leading-8">
              {t("contact.line_02")}
            </p>
            <div className="">
              <p className="text-[16px] text-emerald-950   leading-8">
                {t("contact.line_03")}
              </p>
              <a
                href="https://wildcrimecompass.com"
                target="_blank"
                className="text-[14px] text-blue-700 hover:underline"
              >
                https://wildcrimecompass.com
              </a>
            </div>
          </div>
        </div>
        <div className="mt-0 h-[200px] md:h-[250px] lg:h-[500px] md:w-full lg:w-[500px] rounded-md overflow-hidden flex items-center justify-center">
          <img
            src={mainContactImg}
            alt="about Image"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
